export const validateDate = (
  value: string,
  separator: string,
  regex: RegExp
) => {
  if (value === "") {
    return true;
  }
  if (value.match(regex) === null) {
    return false;
  }
  const [day, month, year] = value.split(separator);
  const validDate = new Date(`${year}-${month}-${day}`);
  const timestamp = validDate.getTime();
  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }
  return validDate.toISOString().startsWith(`${year}-${month}-${day}`);
};
